/* eslint-disable */
import React from 'react';
import moment from 'moment';

import {useFreshState} from '../hooks';
import Editor from '../editor';
import {ARCHIVE_CATEGORIES} from '../constants';

const filterEmpty = object => Object.entries(object).reduce((o, [k, v]) => ({...o, ...(v ? {[k]: v} : {})}), {});

export default ({editorData, setEditorData, add, update}) => {
  const [category, setCategory] = useFreshState(editorData.category || 'sound', [editorData]);
  const isEditing = editorData && Object.keys(editorData).length;
  return (
    <Editor
      editing={isEditing}
      data={editorData}
      iFrame={true}
      hasDescription={true}
      onCancel={_ => {
        setEditorData(null);
        setCategory('sound');
      }}
      onSave={({content, description, iframe, id, order}) => {
        setEditorData(null);
        if (isEditing) {
          update(id, filterEmpty({content, description, iframe, category}));
        } else {
          add(filterEmpty({date: moment().toDate(), content, description, iframe, category, order}));
        }
      }}
    >
      <div>
        Select category -
        <select value={category} onChange={e => setCategory(e.target.value)}>
          {ARCHIVE_CATEGORIES.map(c => <option key={c} value={c}>{c}</option>)}  
        </select>
      </div>
    </Editor>
  );
}