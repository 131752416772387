/* eslint-disable */

import React from 'react';
import {BrowserView, MobileView} from 'react-device-detect';
import {ARCHIVE_CATEGORIES} from '../constants';

export default ({filter, setFilter}) => (
  <div className='bg-white'>
    <BrowserView>
      <div className='flex'>
        {ARCHIVE_CATEGORIES.map(category => (
          <button
            key={category}
            className={`font-semibold py-2 px-4 rounded bg-transparent hover:bg-gray-100 focus:outline-none ${category === filter && 'text-gray-500'} flex-grow`}
            onClick={() => setFilter(oldCat => oldCat === category ? null : category)}
          >
            {category}
          </button>
        ))}
      </div>
    </BrowserView>
    <MobileView>
      <ul>
        <div className='flex'>
          {ARCHIVE_CATEGORIES.slice(0, 2).map(category => (<button
            key={category}
            className={`font-semibold py-2 px-4 rounded bg-transparent focus:outline-none ${category === filter && 'text-gray-500'} flex-grow`}
            onClick={() => setFilter(oldCat => oldCat === category ? null : category)}
          >
            {category}
          </button>))}
        </div>
        <div className='flex'>
          {ARCHIVE_CATEGORIES.slice(2).map(category => (<button
            key={category}
            className={`font-semibold py-2 px-4 rounded bg-transparent focus:outline-none ${category === filter && 'text-gray-500'} flex-grow`}
            onClick={() => setFilter(oldCat => oldCat === category ? null : category)}
          >
            {category}
          </button>))}
        </div>
      </ul>
    </MobileView>
  </div>
);