/* eslint-disable */
import React, {useState, useContext} from 'react';
import moment from 'moment';

import Editor from './editor';
import {useCollection} from './database';
import {AdminContext} from './admin';

import EditIcon from './icons/icon-edit.svg';
import DeleteIcon from './icons/icon-trash.svg';

import './news.css';

export default () => {
  const transformNews = item => {
    const {content, date} = item.data();
    return {id: item.id, content, date: moment(date.toDate())};
  };
  const [news, addNewsItem, deleteNewsItem, updateNewsItem] = useCollection('news', transformNews);
  const [editorData, setEditorData] = useState(null);
  const isEditing = editorData && editorData.content;
  const isAdmin = !!useContext(AdminContext);

  return (
    <div className="news">
      {editorData != null &&
        <Editor
          editing={isEditing}
          data={editorData}
          onCancel={_ => setEditorData(null)}
          onSave={({content, id}) => {
            setEditorData(null);
            if (isEditing) {
              updateNewsItem(id, {date: moment().toDate(), content});
            } else {
              addNewsItem({date: moment().toDate(), content});
            }
          }}
        />
      }
      {isAdmin && 
        <button onClick={_ => setEditorData({})} className="rounded bg-gray-200 hover:bg-gray-400 p-2 shadow">Create post</button>
      }
      {news.sort((a, b) => Number(b.date) - Number(a.date)).map(({date, content, id}, i) => (
        <div className="overscroll-y-hidden" key={`${content}${i}`}>
          {isAdmin &&
            <div className="inline-flex justify-right">
              <a onClick={_ => setEditorData({date, content, id})}><img src={EditIcon}></img></a>
              <a onClick={_ => {
                if (confirm('are you sure you want to delete this item?')) {
                  deleteNewsItem(id);
                }
              }}><img src={DeleteIcon}></img></a>
            </div>
          }
          <div className="underline font-bold">{date.format('MMMM. DD. YYYY')}</div>
          <div dangerouslySetInnerHTML={{__html: content}} className="my-3 mb-10"></div>
        </div>
      ))}
    </div>
  );
};