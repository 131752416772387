/* eslint-disable */
import React, {useState, useEffect} from 'react';
import {Route, BrowserRouter as Router, Switch, useHistory} from 'react-router-dom';
import * as firebase from 'firebase/app';
import 'firebase/auth';
import {BrowserView, isMobile} from 'react-device-detect';

import Nav from './nav';
import Admin, {AdminContext} from './admin';
import News from './news';
import About from './about'
import Booth from './booth';
import Archive from './archive';

const Image = () => {
  return (
    <div className="">
      <img src="water.jpg" className={`h-screen absolute right-0`} style={{width: 'calc(100% * 3/12)'}}/>
    </div>
  );
};

const superAdmins = ['marcelrusu0@gmail.com', 'nofuneral8@gmail.com']

const Home = () => {
  const [admin, setAdmin] = useState(null);
  const history = useHistory()
  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged(user => {  
      setAdmin(user);
      if (user && superAdmins.includes(user.email)) {
        history.push('/');
      }
    });
    return () => unsubscribe();
  }, []);  
  return (
    <AdminContext.Provider value={admin}>
      {admin &&
        <button
          className="absolute bg-red-200 shadow m-8 p-2 rounded font-sans hover:bg-red-400"
          onClick={() => firebase.auth().signOut()}
        >
          Log out
        </button>
      }
      <div className="flex h-screen p-0 overflow-hidden">
        <Nav items={['News', 'Listen', 'Shop', 'Archive', /* 'Interviews', */ 'Booth', 'About']} />
        <div className={`mt-10 w-5/12 ${isMobile && 'ml-0 w-full mr-2 mb-10'} overflow-y-scroll`}>
          <Route exact path={['/', '/news']}>
            <News />
          </Route>
          <Route exact path='/about'>
            <About />
          </Route>
          <Route exact path='/booth'>
            <Booth />
          </Route>
          <Route exact path='/archive'>
            <Archive />
          </Route>
        </div>
        <BrowserView>
          <Image />
        </BrowserView>
      </div>
    </AdminContext.Provider>
  );
};


const App = () => (
  <Router>
    <Switch>
      <Route exact path="/admin">
        <Admin />
      </Route>
      <Home />
    </Switch>
  </Router>
)

export default App;
