/* eslint-disable */
import React, {useState, useContext} from 'react';
import moment from 'moment';
import {DragDropContext, Droppable} from 'react-beautiful-dnd';

import Item from './item';
import Header from './header';
import ArchiveEditor from './editor';
import {db, useCollection} from '../database';
import {useArray} from '../hooks';
import {AdminContext} from '../admin';

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

const onDragEnd = (releases, setOrdering) => result => {
  if (!result.destination) return;
  reorder(releases, result.source.index, result.destination.index)
    .map(([__, setItem], i) => setItem(item => ({...item, order: i + 1})))
  setOrdering(true)
};


export default () => {
  const [releases, addRelease, deleteRelease, updateRelease, refresh] = useCollection('archive', item => {
    const {category, content, description, iframe, date, order} = item.data();
    return {id: item.id, content, iframe, description, category, order, date: moment(date.toDate())};
  });
  let [releasesState] = useArray(releases, [releases]);
  const [editorData, setEditorData] = useState(null);
  const [ordering, setOrdering] = useState(false);
  const isAdmin = !!useContext(AdminContext);
  const [filter, setFilter] = useState('sound');
  if (filter) releasesState = releasesState.filter(([{category}]) => category === filter);
  
  return (
    <DragDropContext onDragEnd={onDragEnd(releasesState, setOrdering)}>
      {editorData != null &&
        <ArchiveEditor
          editorData={editorData}
          setEditorData={setEditorData}
          add={addRelease}
          update={updateRelease}
        />
      }
      {isAdmin &&
        <>
          <button onClick={_ => {
            setEditorData({});
          }} className="rounded bg-gray-200 hover:bg-gray-400 p-2 shadow">Create post</button>
          {ordering && <button onClick={async () => {
            setOrdering(false);
            await db.runTransaction(async t => {
              releasesState.map(([item]) => t.update(db.collection('archive').doc(item.id), {order: item.order}));
            });
            refresh();
          }} className="rounded float-right bg-green-200 hover:bg-green-400 p-2 shadow">Save order</button>}
        </>
      }
      <Header filter={filter} setFilter={setFilter} />
      <Droppable droppableId='archive-list'>
        {provided => (
          <div
            style={{height: '85vh'}}
            {...provided.droppableProps}
            ref={provided.innerRef}
          >
            {releasesState.sort(([a], [b]) => Number(a.order) - Number(b.order)).map(([elem, setElem], i) =>
              <Item
                key={elem.id}
                isLast={i === releasesState.length - 1}
                isAdmin={isAdmin}
                elem={elem}
                deleteRelease={deleteRelease}
                index={i}
                setElem={setElem}
                setEditorData={setEditorData}
              />
            )}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};