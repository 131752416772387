/* eslint-disable */
import React, {useState} from 'react';
import CKEditor from 'ckeditor4-react';
  
const unsafify = html => html?.replaceAll('&lt;', '<')?.replaceAll('&quot;', '"')?.replaceAll('&gt;', '>');

export default ({editing, onSave, onCancel, hasDescription, iFrame,  data: {content, description, iframe, id}, children}) => {
  const [text, setText] = useState(content);
  const [text2, setText2] = useState(description);
  const [iframeText, setIframe] = useState(iframe);
  return (
    <>
      <div className='z-10 bg-gray-900 bg-opacity-25 top-0 left-0 absolute w-screen h-screen'></div>
      <div className="z-50 draggable left-0 h-screen w-full absolute flex items-center justify-center bg-modal top-0">
        <div className="bg-white rounded shadow-lg p-8 m-4 w-7/12 h-7/12 max-h-full text-center overflow-y-scroll">
          <div className="mb-4">
            <h1>{editing ? 'Edit' : 'Add'}</h1>
            {children}
          </div>
          <CKEditor data={text} onChange={({editor}) => setText(editor.getData())}/>
          {hasDescription && <>
            <span>Description</span>
            <CKEditor data={text2} onChange={({editor}) => setText2(editor.getData())}/>
          </>}
          {iFrame && <div>Bandcamp Embed
            <textarea className="border-solid border-2 mt-5 w-full font-mono" placeholder='Insert bandcamp embed here...' onChange={e => setIframe(e.target.value)} value={iframeText} />
          </div>}
          <div className="mt-5">
            <button onClick={onCancel} className="float-left py-2 px-4 rounded bg-red-200 hover:bg-red-400">Cancel</button>
            <button onClick={_ => onSave({content: text && unsafify(text), description: text2 && unsafify(text2), iframe: iframeText, id})} className="float-right py-2 px-4 rounded bg-green-200 hover:bg-green-400">Save</button>
          </div>
        </div>
      </div>
    </>
  );
};
