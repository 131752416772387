/* eslint-disable */
import {useState, useEffect} from 'react';
import * as firebase from "firebase/app";
import 'firebase/firestore';
import 'firebase/auth';

const firebaseConfig = {
  apiKey: "AIzaSyDXGeeb5IYKz1KXaOFbGZqm4h4BF8sXDrY",
  authDomain: "no-funeral.firebaseapp.com",
  databaseURL: "https://no-funeral.firebaseio.com",
  projectId: "no-funeral",
  storageBucket: "no-funeral.appspot.com",
  messagingSenderId: "602212493529",
  appId: "1:602212493529:web:bdfc8bb16e31cf5d62f6c8"
};
firebase.initializeApp(firebaseConfig);

export const db = firebase.firestore();

export const useCollection = (collectionRef, transform) => {
  const currentUser = firebase.auth().currentUser;
  const collection = db.collection(collectionRef);
  const [items, setItems] = useState([]);
  const getData = () => {
    const unsubscribe = collection.onSnapshot(res => {
      let data = [];
      res.forEach(item => {
        data.push(transform(item))
      });
      setItems(data);
    });
    return () => unsubscribe();
  }
  useEffect(getData, [currentUser]);
  const addItem = item => collection.add(item).then(getData);
  const removeItem = id => collection.doc(id).delete().then(getData);
  const updateItem = (id, newData, bulk = false) =>
    collection.doc(id).update(newData).then(() => {
      if (!bulk) getData();
    });

  return [items, addItem, removeItem, updateItem, getData];
}

export const useItem = (collectionRef, itemRef, transform) => {
  const currentUser = firebase.auth().currentUser;

  const [item, setItem] = useState(null);
  const get = () => {
    const unsubscribe = db.collection(collectionRef)
      .doc(itemRef)
      .onSnapshot(item => setItem(transform(item)));
    return () => unsubscribe();
  };
  const update = newData => db.collection(collectionRef).doc(itemRef).update(newData).then(get)
  
  useEffect(get, [currentUser]);
  return [item, update];
}