/* eslint-disable */
import React, {useState, useContext} from 'react';
import moment from 'moment';

import Editor from './editor';
import {useItem} from './database';
import {AdminContext} from './admin';

export default () => {
  const transform = item => {
    const {content, updated_at} = item.data();
    return {id: item.id, content, updated_at: moment(updated_at.toDate())};
  };
  const [about, updateAbout] = useItem('meta', 'about', transform);
  const [editorData, setEditorData] = useState(null);
  const isEditing = !!editorData;
  const isAdmin = !!useContext(AdminContext);
  return (
    <>
      {editorData != null &&
        <Editor
          editing={isEditing}
          data={editorData}
          onCancel={_ => setEditorData(null)}
          onSave={({content}) => {
            setEditorData(null);
            updateAbout({content});
          }}
        />
      }
      {isAdmin && 
        <button onClick={_ => setEditorData({updated_at: about.updated_at, content: about.content})} className="rounded bg-gray-200 hover:bg-gray-400 p-2 shadow">Edit About</button>
      }
      {isAdmin && <div className="font-bold">Last updated at - {about && about.updated_at.format('MMMM. DD. YYYY')}</div>}
      <div dangerouslySetInnerHTML={{__html: (about && about.content) || ''}} className="my-3 mb-10"></div>
    </>
  );  
};