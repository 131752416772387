/* eslint-disable */
import React from 'react';
import FacebookIcon from './icons/fb.svg';
import {Link, useLocation} from 'react-router-dom';
import {isMobile} from 'react-device-detect';

export default ({ items }) => {
  const {pathname} = useLocation();
  const toLink = l => `/${l.toLowerCase()}`;
  const createLink = link => {
    const className = `block ${isMobile ? 'py-2' : 'p-4 pl-6'} text-xl hover:underline`;
    if (link === 'Listen') {
      return <a target="_blank" className={className} href="https://nofuneralsound.bandcamp.com/">{link}</a>
    } else if (link === 'Shop') {
      return <a className={className} href="http://store.nofuneral.ca/">{link}</a>
    } else {
      return (
        <Link
          className={`${className} ${pathname === toLink(link) && 'underline font-bold'}`}
          to={toLink(link)}
        >
          {link}
        </Link>
      );
    }
  }

  return (
    <div className={`bg-white w-2/12 mt-${isMobile ? 8 : 20} mx-6`}>
      <ul className="list-reset">
        <li className={`${isMobile ? '-ml-4' : 'mr-6'}`} style={{width: isMobile ? '80px' : '100px'}}>
          <Link to="/">
            <img src="logo192.png" />  
          </Link>
        </li>
        {items.map(link => <li key={link} className="">{createLink(link)}</li>)}
        <li className={`${isMobile ? 'py-2' : 'p-4 pl-6'} text-lg`}>
          <Link to='/'>////</Link>
        </li>
        <li className={`flex bottom-0 mb-5 ${isMobile && '-ml-4'} absolute`}>
          <a className='w-5 mr-1' href="https://www.instagram.com/nofuneral.online/" target="_blank">
            <img src="https://upload.wikimedia.org/wikipedia/commons/6/65/Black_Instagram_icon.svg"/>
          </a>
          <a className='w-5 mr-1' href="https://www.facebook.com/nofuneral.8/" target="_blank">
            <img className="w-full" src={FacebookIcon} />
          </a>
          <a className='w-12' href="https://nofuneralsound.bandcamp.com" target="_blank">
            <img className='' style={{marginTop: '3px'}} src="https://upload.wikimedia.org/wikipedia/commons/3/35/Bandcamp-bc-logotype-dark.svg"/>
          </a>
        </li>
      </ul>
    </div>
  );
}