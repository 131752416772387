/* eslint-disable */

import React from 'react';
import {Draggable} from 'react-beautiful-dnd';
import posed, {PoseGroup} from 'react-pose';

import EditIcon from '../icons/icon-edit.svg';
import DeleteIcon from '../icons/icon-trash.svg';
import DownIcon from '../icons/icon-arrow-down.svg';


const AnimatedIcon = posed.img({
  up: {transform: 'rotate(0deg)'},
  down: {transform: 'rotate(180deg)'}
})
const HiddenDiv = posed.div({
  enter: { opacity: 1, height: '100%' },
  exit: { opacity: 0, height: 0 }
});

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  padding: 5,
  margin: `0 0 ${5}px 0`,
  // styles we need to apply on draggables
  ...draggableStyle
});

export default ({isAdmin, isLast,  deleteRelease, setEditorData, setElem, elem: {date, content, description, category,  show, iframe, id, order}, index}) => (
  <Draggable isDragDisabled={!isAdmin} draggableId={id} index={index}>
    {(provided, snapshot) => (
      <div
        className="overflow-y-hidden my-3 mb-10"
        ref={provided.innerRef}
        style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
      >
        <span className='flex cursor-pointer' onClick={() => setElem(elem => ({...elem, show: !elem.show}))} >
          {isAdmin &&
            <div className="inline-flex mt-3 mr-5 justify-right">
              <a onClick={_ => {
                setEditorData({date, content, description, iframe, id, order, category});
              }}><img src={EditIcon}></img></a>
              <a onClick={_ => {
                if (confirm('are you sure you want to delete this item?')) {
                  deleteRelease(id);
                }
              }}><img src={DeleteIcon}></img></a>
            </div>
          }
          {(iframe || description) && <AnimatedIcon pose={show ? 'down' : 'up'} src={DownIcon} className="mr-2" style={{width: '16px', height: 'min-content'}} />}
          <div className="" dangerouslySetInnerHTML={{__html: content}} />
        </span>
        <PoseGroup>
          {show ? <HiddenDiv key={id}>
            <div className='text-sm' dangerouslySetInnerHTML={{__html: description}} />
            <div className='w-full' dangerouslySetInnerHTML={{__html: iframe}} />
          </HiddenDiv> : null}
        </PoseGroup>
      </div>
    )}
  </Draggable>
);
