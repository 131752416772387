/* eslint-disable */
import React, {useContext, useState} from 'react';
import moment from 'moment';

import {useCollection} from './database';
import {AdminContext} from './admin';

import DeleteIcon from './icons/icon-trash.svg';

const EMPTY_FORM = Object.freeze({name: '', email: '', content: ''});
export default () => {
  const [msgs, addMsg, removeMsg] = useCollection('booth', item => {
    const {name, content, email, date} = item.data();
    return {id: item.id, name, content, email, date: moment(date.toDate())};
  });
  const isAdmin = !!useContext(AdminContext);
  const [form, setForm] = useState(EMPTY_FORM);
  
  return (
    <>
      <form className="block font-mono" onSubmit={e => {
        e.preventDefault();
        addMsg({...form, date: moment().toDate()});
        setForm(EMPTY_FORM);
      }}>
        <input className="w-full border" value={form.name} onChange={e => setForm({...form, name: e.target.value})} required type="text" placeholder="name"/>
        <input className="w-full border" value={form.email} onChange={e => setForm({...form, email: e.target.value})} type="text" placeholder="email (optional)"/>
        <textarea className="w-full border" value={form.content} onChange={e => setForm({...form, content: e.target.value})}  placeholder="message..." required />
        <input className="w-full border" type="submit" value="Submit" />
      </form>
      {msgs.sort((a, b) => Number(b.date) - Number(a.date)).map(({name, content, email, date, id}) => (  
        <React.Fragment key={id}>
          <div className="my-3 mb-10">
            {isAdmin &&
              <a onClick={_ => {
                if (confirm('Are you sure you want to delete this message?')) {
                  removeMsg(id);
                }
              }}><img src={DeleteIcon}></img></a>}
            {name}
            <hr/>
            {isAdmin &&
              <>
                {email}
                <hr/>
              </>
            }
            {date.format('LLL')}
            <hr/>
            {content}
          </div>
          <hr/>
        </React.Fragment>
      ))}
    </>
  );
};